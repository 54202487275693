.there_img_careers{
    margin-right: 0;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: transparent; */
    background-image: linear-gradient(
        to right,
        white 24%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("https://images.pexels.com/photos/733854/pexels-photo-733854.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
      
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
    animation: fadeIn 2s ease-in-out forwards;

}
@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
.post_box{
    border: 1px solid black;
    margin: 12px;
    height: 280px;
    width: 400px;
    padding: 1rem;
    border-radius: 12px;
}
.detail_post{
    font-size: small;
}
.opening_head{
    color: orangered;
    margin-top: 2rem;
}
.need_row{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid black ;
}
.apply_btn_link:hover{
    color: orangered;
}
.span_left_margin{
    margin-left: 3px;
}
.custom-li {
    padding: 10px; /* adjust the value to control the padding */
    margin-bottom: 10px; /* adjust the value to control the margin */
    gap: 5px;
  }
  /* .custom-li .text {
    margin-bottom: 50px; 
  } */
  .latest_openings{
    border-bottom: 1px solid #000;
  }
@media (max-width:425px) {
.post_box{
    width: 250px;
}
.apply_btn{
    margin-top: -30px;
}
}
@media (max-width:290px) {
    .detail_post{
        font-size:xx-small;
    }
    .post_box{
        width: 180px;
    }
    .apply_btn{
        margin-top: -40px;
    }
}