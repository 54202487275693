.custom_image{
    width: 20em; /* Adjust the desired width */
    height: 8em;
    object-fit: cover;
    /* background-color: rgb(33, 30, 30); */
    background-color:rgb(32, 32, 143);
    border-radius: 5px;
    /* color: #415575; */
    color: white;
    padding: 5px;
}
.custom_image:hover{
    transform: scale(1.01);
    transition: all 0.3s ease-in-out;
}
.swiper-container{
    width: 95%; /* Adjust the width as per your requirement */
    margin: 0 auto; /* Center the swiper container */
}