@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:wght@300;400;500;700;900&display=swap');
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
}

body{
    overflow-x: hidden;
  
    display: block;
    
    margin: 0;

    background-color:rgb(242, 249, 245);

}

a{
    text-decoration: none;
}
