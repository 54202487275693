.service_list{
    background-color: rgb(242, 249, 245);
 
    max-height: auto;
    
    }
    .service-card {
      width: 100%;
      height: 150px;
      border: none;
      border-radius: 1rem;
      background-color: white;
        box-shadow: rgba(78, 78, 156, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      opacity: 0.9;
      transition: all 0.7s ease-in-out;
       text-align: center;
    }
    .service-card:hover{
      color: white;  
      background-color: #46C2CB; 

    }
    .serv_Card{
      margin-bottom: 1rem;
      width:16rem;
      height: 16rem;

      animation-name: floatingAnimation;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    
    }
  .services_img{
    margin-right: 0;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: transparent; */
  background-image: linear-gradient(
      to right,
      white 24%,
      rgba(255, 255, 255, 0) 100%
    ),
    url("https://images.pexels.com/photos/845451/pexels-photo-845451.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: fadeIn 1s ease-in-out forwards;

  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .left_content_contact_inner_serv{
    margin-left: -1 !important;
    animation-name: floatingAnimation;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 3;
  }
  @media (max-width: 768px) {
    .services_img{
      display: none;
    }
  }

  .service_list_link{
    border: none;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 10px;
  background: linear-gradient(90deg, rgba(105, 136, 223, 0.5), rgb(56, 192, 216), rgba(93, 128, 202, 0.5));
  }
  .div_more_serviceLink{
    margin-bottom: 3rem;
  }