.banner_within_services{
    /* border: 1px solid black; */
    border-radius: 2rem;
    margin-bottom: 4rem;
    height: auto;
    padding: 1rem;
}
.connect_btn_service{
  border-radius: 10px;
  margin-top: 0.5rem;
  border: none;
  width: 10rem;
  height: 5rem;
}
.topMostImg{
  animation: fadeIn 1s ease-in-out forwards;

}
 @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
.service_banner_bubble{
    background-color: white; 
    margin-bottom: 1rem;
    /* border: 1px solid black; */
    margin-left: 50px;
    border-radius: 2rem;
    width: 18rem;
    height: 18rem;
    padding: 0.5rem;
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3);
filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.3));

}

.service_banner_bubble :hover{
   transform: scale(1.1);
   transition: all .3s ease-in-out;

}
@media (max-width:768px) {
    .service_banner_bubble{
        margin-left: 20px;
    }
}
@media (max-width:527px){
    .service_banner_bubble{
        margin-left: 15px;
    }
}
@media (max-width:279px){
    .service_banner_bubble{
        margin-left: 2px;
    }
}