.cont_about {
  background-color: rgb(242, 249, 245);
  min-height: 80vh;
}

.card_Container {
  background-color: rgb(242, 249, 245);

 
}
.cont_about_inner {
  padding-top: 10rem;
}


/* Below is the first banner on about us  */
.outer_box_there_img {
  position: relative;
  min-width: 100vw;
  min-height: 40vh;
  background-color: rgb(242, 249, 245);

}
.left_content_about {
  display: flex;
  align-items: center;
  width: auto;

}
.left_content_about_inner {
  width: 100%;
  animation-name: floatingAnimation;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 3;
}
.sibling_there_img {
  position: absolute;
  z-index: 1;
  top: 1rem;
  margin-left: -1rem;

 
}

.there_img {
  margin-right: 0;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: transparent; */
  background-image: linear-gradient(
      to right,
      white 24%,
      rgba(255, 255, 255, 0) 100%
    ),
    url("https://images.pexels.com/photos/4065876/pexels-photo-4065876.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: fadeIn 2s ease-in-out forwards;


}
.about_text_p{
  /* text-align: justify; */
  font-size: small;
 
}
 @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

@media (max-width: 468px) {
  .there_img {
    display: none;
  }
  
}
@media (min-width: 280px) and (max-width: 590px) {
  .about_text_p{
    font-size: xx-small;
  }
  .left_content_about{
    width: 258px;
   }
}
@media (max-width: 280px) {
 .left_content_about{
  width: 258px;
 }
}
@media (min-width: 590px) and (max-width: 1084px){
  .left_content_about{
    width: 437px;
   }
}
@media (max-width: 420px) {
  .about_text_p{
    font-size:xx-small;
  }



}
  


/* Below is the second banner on about us  */
.outer_second_banner{
  background-color: rgb(241, 235, 235);
  min-width: 100vw;
  min-height: 60vh;
  
}
.values_text_heading{
  color: black;
  font-weight: 800;
}

.heart_about{
  animation-name: floatingAnimation;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 3;
}
.section {
  width: 20rem;
  padding: 4rem;
  margin-bottom: 30px;
  background-image: linear-gradient(
    90deg,
    hsl(116deg 14% 78%) 0%,
    hsl(117deg 18% 77%) 11%,
    hsl(117deg 21% 77%) 22%,
    hsl(118deg 23% 76%) 33%,
    hsl(118deg 26% 76%) 44%,
    hsl(119deg 29% 75%) 56%,
    hsl(119deg 31% 74%) 67%,
    hsl(120deg 33% 74%) 78%,
    hsl(121deg 36% 73%) 89%,
    hsl(121deg 38% 72%) 100%
  );
  border-radius: 3rem;
}
.col_about {
  margin-right: 5rem;
}
h1,
h2 {
  color: #333;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}
.content{
  margin-top: 1rem;
}
 



/**********************************************team details*********************************************************/
.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 3rem;
}

.card_about_page {
  width: 220px;
  height: 300px;
  perspective: 1000px;
  transform-style: preserve-3d;
  position: relative;
  border: none;
  background-color: transparent;

  animation-name: floatingAnimation;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 5;
}

.card-front,
.card-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s;

}

.card-front {

  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: rotateY(0deg);

  
}

.card-back {
  background-color: #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: rotateY(180deg);


}

.card_about_page.flipped .card-front {
  transform: rotateY(180deg);
}

.card_about_page.flipped .card-back {
  transform: rotateY(0deg);
}

.card-front img,
.card-back img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.about_Head{
  font-size: x-small;
}
.about_Head_other{
  font-size: small;
}
.link_media{
  margin-top: -22px;
}



/******************************************************************************************************/



@media (max-width: 910px) {
  .section {
    padding: 1rem;
    width: 15rem;
  }
  .col_about {
    margin-right: 8rem;
  }
  .about_row {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
@media (min-width: 910px) and (max-width: 1200px) {
  .section {
    padding: 1rem;
    width: 13rem;
  }
  .about_row {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
@media (max-width: 400px) {
  .section {
    width: 10rem;
  }
  .about_row {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
