.list_services{
    background-color: rgb(242, 249, 245);
    min-width: 100vw;
    min-height: 60vh;

}
.services_ul{
   margin-top: 3rem;
   list-style-type: disc;
}
.li_ListService{
    margin-left: 4rem;
}
.bottom_service_banner{
    background-color: blueviolet;
    padding: 3rem;
    color: white;
}
.thick_font{
    font-size:larger;
    font-weight: 700;
}
.service_list_tag {
    font-weight: 900;
    background: linear-gradient(
        45deg,
        hsl(0deg 83% 46%) 0%,
        hsl(9deg 86% 47%) 10%,
        hsl(15deg 89% 48%) 20%,
        hsl(19deg 93% 49%) 30%,
        hsl(24deg 97% 49%) 40%,
        hsl(27deg 100% 50%) 50%,
        hsl(36deg 100% 45%) 60%,
        hsl(46deg 100% 39%) 70%,
        hsl(62deg 100% 33%) 80%,
        hsl(79deg 100% 35%) 90%,
        hsl(106deg 78% 42%) 100%
);
background-clip: text;
-webkit-background-clip: text;
color: transparent;
}