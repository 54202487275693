.foot_whole{
    /* margin-top: 1rem; */
    /* background-color: rgb(194, 237, 224) ; */
    background-color: #4D455D;
    padding-top: 4rem;
}
.foot_down{
    margin-top: 4rem;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-left: 2px; */
}
/* .contact_elements_info{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
} */
.contact_elements{
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    color: white;
    font-size: small;
}
.contact_elements:hover{
    color: black;
}
.quick_links{
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  color: white;
  font-size: small;
  margin-left: 2rem;
}
.quick_links:hover{
    color: black; 
}
.foot_headers{
    color:white;
}
/* .contact_elements p{
    margin-left: 10px;
} */
a{
    text-decoration: none;
}
.for_nowrap{
    white-space: nowrap;
   
}
.foot_btn{
    background-color: gray;
    color: white;
    transition: all 0.3s ease-in-out;
}
.foot_btn:hover{
    /* background-color:#2D2727 ; */
    background-color:rgb(48, 181, 214);
    color: white;
}
.foot_down .for_nowrap p {
    margin-bottom: 2rem; /* Remove bottom margin */
  }
.foot_link{
    color: white;
}
.foot_link:hover{
    color: black;
}
.for_margin{
    margin-right: 6rem;
}
.for_Left_border{
    border-left: 1px solid white;
    padding-left: 0.5rem;
}
.font_btm{
    font-size: small;
   
    /* background-color: black;
    min-width: 100vw; */

}
/* .font_btm_outer{
    background-color: black;
   min-width: 100vw;
} */
.icon_foot{
    border: 1px solid #9336B4;
    display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 2px;
  transition: transform 0.3s ease;

}


.icon_foot:hover{
    transform: rotate(360deg);
    border: 1px solid gray;
    border-radius: 0px;
    background-color: #3b5998 ;
}
.icon_foot_insta{
    border: 1px solid #9336B4;
    display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 2px;
  transition: transform 0.3s ease;
}
.icon_foot_insta:hover{
    transform: rotate(360deg);
    border: 1px solid gray;
    border-radius: 0px;
    background-color: pink;
}
.icon_foot_twit{
    border: 1px solid #9336B4;
    display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 2px;
  transition: transform 0.3s ease;
}
.icon_foot_twit:hover{
    transform: rotate(360deg);
    border: 1px solid gray;
    border-radius: 0px;
    background-color: #00acee;
}
.icon_foot_linkedin{
    border: 1px solid #9336B4;
    display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 2px;
  transition: transform 0.3s ease;
}
.icon_foot_linkedin:hover{
    transform: rotate(360deg);
    border: 1px solid gray;
    border-radius: 0px;
    background-color: #0A66C2;
}
.icon_foot_youtube{
    border: 1px solid #9336B4;
    display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 2px;
  transition: transform 0.3s ease;
}
.icon_foot_youtube:hover{
    transform: rotate(360deg);
    border: 1px solid gray;
    border-radius: 0px;
    background-color: #FF0000;
}


/* .rotating-element {
    transition: transform 0.6s ease;
  } */
  
  /* .rotating-element:hover {
    transform: rotate(360deg);
  } */

@media (max-width:1200px) {
    
.foot_down{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
    
}
@media (max-width:991px) {
    .for_Left_border{
        border-left: none;
        padding-left: 0.5rem;
    }
    .for_bottom_border{
        border-bottom: 1px solid gray;
    }
}
@media (max-width:366px) {
    .for_nowrap{
        white-space:normal;
       
    }

}
  /* Adjust the right margin as needed */
  /* .foot_down .for_nowrap p:not(:last-child) {
    margin-right: 30px; 
  } */