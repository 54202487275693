header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    padding: 0 1rem;
    
    color: black;
    z-index: 99;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: rgb(242,249,245);
    padding: 50px;
    font-size: 20px;
  }
  a{
    margin: 0 0.5rem;
  }
  .nav_link{
    font-size: medium;
    color: black;
    margin-right: 2rem;
    font-weight: 500;
    position:relative;
   
  }
  .nav_link::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%; /* Start from the middle of the element */
    transform: translateX(-50%);
    width: 0;
    height: 1px;
    background-color: red;
    transition: width 0.3s ease-in-out;
  }
  
  /* Expand the border from the middle to full width */
  .nav_link:hover::before {
    width: 100%; 
  }
  
  .nav_link:hover {
    color: red;
    transition: color 0.3s ease-in-out;
  }
  /* .nav_link:hover{
    color:blue;
    border-bottom: 1px solid blue;
    transition:all 0.5s ease-in-out ;
    
  } */
  .navbar_brand{
    width: 10rem;
    transition:all 0.3s ease-in-out ;
  
  }
  .navbar_brand:hover{
   transform: scale(1.2);
  }
  .navbar_brand img{
    width:10rem;
  }
  .nav_btn{
    padding: 5px;
    background: transparent;
    border: none;
    outline:none;
    color:black;
    display: none;
    
  }
  form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  form input,form select{
    width: 15rem;
  }
  
  form input,
  form select,
  form button {
    /* width: 100%;   */
    margin-bottom: 5px;
    border: none;
    padding: 5px;
    border-radius: 10px;
  }
  
  .consult_form{
    background-color: aliceblue;
    height:410px;
    width: 290px;
    z-index: 9999;
    position: absolute;
    top: 5rem;
    right: 5rem;
    padding: 25px 30px;
    border: 1px solid skyblue;
    border-radius: 20px;
   text-align: center;
    /* right:-150%; */
    display: none;
    transition: right 0.3s ease-in-out;
  }
  /* .consult_Form_button:hover + .consult_form, .consult_form:hover{
    right: 10rem;
  } */
.consult_Form_button{
  /* animation: buttonAnimation 2s ease-in-out infinite; */

}
@keyframes buttonAnimation {
  0% {
    transform: scale(1); /* Initial size of the button */
  }
  50% {
    transform: scale(1.1); /* Intermediate size of the button */
  }
  100% {
    transform: scale(1); /* Final size of the button */
  }
}
  .consult_form_unhide{
    transition: right 0.3s ease-in-out;
    right: 10rem;
    background-color: aliceblue;
    height:410px;
    width: 290px;
    z-index: 9999;
    position: absolute;
    top: 5rem;
    /* right: 5rem; */
    display: block;
    padding: 25px 30px;
    border: 1px solid skyblue;
    border-radius: 20px;
   text-align: center;
  }
  @media (max-width:1191px) {
    .consult_Form_button{
      display: none;
    }

    .consult_form{
      display: none;

    }

    .consult_form_unhide{
      display: none;

    }
    
  }
  .top_icons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .top_icons_indiv{
    margin-right: 15px;
  }
  
  /* .consult_form:hover{
    opacity: 1;
  } */
  .span_div{
   
    border-radius: 5px;
    padding: 0.5px;
    white-space: nowrap;
   
  color: blue;
    
  }
  .wa_icon{
  transition: all 0.3s ease-in-out;
  }
  .wa_icon:hover{
    transform: scale(1.2);
  }
  .btn_div{
    display: flex;
    padding: 5px;
    /* flex-direction: row; */
    /* justify-content: space-between; */
   /* margin-right: 10px; */
    margin-top: 5px;
  }
  .consult_btn_sub{
    background-color: rgb(126, 173, 214);
    padding: 5px;
  margin-right: 5px;
  transition: all 0.3s ease-in-out;
  }
  .consult_btn_sub:hover{
    background-color: rgb(66, 209, 53);
    padding: 8px;
    color: aliceblue;
    border-radius: 5px;
  }
  .consult_btn_can{
    background-color: rgb(126, 173, 214);
    padding: 5px;
  margin-right: 40px;
  transition: all 0.3s ease-in-out;
  }
  .consult_btn_can:hover{
    background-color: rgb(221, 52, 22);
    padding: 8px;
    color: aliceblue;
    border-radius: 5px;
  }
  iframe{
    transform:scale(0.77);
    transform-origin:0 0;
  }
  @media (max-width:991px) {
    .nav_btn{
        /* visibility: visible;
        opacity: 1; */
      display: block;
    }
    .navbar_brand img{
      width:8rem;
    }
    nav{
   
       display: none;
    }
    .top_btn{
        display: none;
    }
    .responsive_nav{
        display: block;
        font-size:smaller;
        display: flex;
        flex-direction: column;
    }
    .navbar_brand{
        width: 12rem;
        position: absolute;
        top: 1rem;
        left: 2rem;
    }
    .head{
        padding: 14rem 0;
    }
    .nav_link{
        margin: 0.5rem 2.5rem;
        font-size: medium;
       
    }
    header{
        display: flex;
    }
    .nav_btn{
        position: absolute;
        top: 2rem;
        right: 2rem;
    }
    .nav_close_btn{
        position: absolute;
        top: 2rem;
        right: 2rem;
    }
   
  }
  @media (max-width:290px){
    .navbar_brand img{
      width: 6rem;
    }
  }