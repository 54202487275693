
/*************************************************************************************/
.card_container {
    background-color:rgb(242, 249, 245);
    
    width: 100%;
    text-align: center;
    margin-top: 10px;
    min-height: 40vh;
  
    
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .img_home{
    border-radius: 10px;
    align-items: center;
    height: 190px;
    width: 500px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1px;
    object-fit:cover;
    transition: transform 0.3s ease-in-out;
    /* position: relative; */
    z-index: -1;
  }
 .img_main_div{
  position: relative;

  
 }
 .img_main_div_blur{
  border-radius: 5px;
  width: 500px;
  height: 170px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 25px 0 20px -20px rgba(0, 0, 0, 0.45), -25px 0 20px -20px rgba(0, 0, 0, 0.45);

  
 }
 .img_main_div_blur:hover{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px 0px, rgba(0, 0, 0, 0.05) 0px 1px 6px 0px, rgba(0, 0, 0, 0.1) 0px 8px 8px 0px, rgba(0, 0, 0, 0.1) 0px 16px 16px 0px, rgba(0, 0, 0, 0.15) 8px 32px 32px 0px, rgba(0, 0, 0, 0.15) 8px 64px 64px 0px;
  transition: all 0.3s ease-in-out;
 }
 
 .modal_text {
  position: absolute;
  color: black;
  bottom:0;
  font-weight: 800;
  left: 0;
  width: 100%;
  border-bottom-left-radius: 10px; 
  border-bottom-right-radius: 10px;
  /* Place it above the image */
  z-index: 1; 
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.8);
 }
 @media (max-width:991px) {
  .modal-content img{
    width:500px;
 }
 }
 @media (max-width:590px) {
  .modal-content img{
    width:450px;
 }
 }
 @media (max-width:470px){
  .modal-content img{
    width:400px;
    
 }

 .Typewriter{
  height: 50px;
  font-size: small;
 }
 
 }
 @media (max-width:420px){
  .modal-content img{
    width:350px;
    
 }

 .Typewriter{
  font-size: x-small;
 }
 
 }
 @media (max-width:370px){
  .modal-content img{
    width:310px;
    
 }


 
 }
 @media (max-width:321px){
  .modal-content img{
    width:280px;
   
 }
 } 

.modal_close_icon{
  position: absolute;
  
  top: 0;
  right: 0;
  z-index:1;
}
.modal_left_icon{
  cursor: pointer;
  position: absolute;
  
  top: 45%;
  left: 0;
  z-index:5;
}
.modal_right_icon{
  cursor: pointer;

  position: absolute;
  
  top:45%;
  right: 0;
  z-index:5;
}



  
  .tag {
    position: absolute;
    z-index: 99;
    white-space: nowrap;

    top: 50%;
    left:50%;
    transform: translate(-50% , -50%);
    border: none;
    background-color: rgb(69, 133, 189);
    color: white;
    border-radius: 5px;
    pointer-events: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    /* Add other styling properties as needed */
  }
  .wrapper_clik{
    cursor: pointer;
  }
  .tag:hover{
    background-color: rgb(95, 142, 183);
    color: white;
  }
  .img_home:hover{
    
    transform: scale(1.0500);
    transition: all 0.3s ease-in-out;
  }
  .swiper_div{
    padding: 1rem;
  }
 
 .heading_contianer{
    max-width: 70vw;
 }
 .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
   /* Semi-transparent overlay color */
  background-color: rgba(0, 0, 0, 0.6);
   /* Apply the blur effect to the backdrop */
  backdrop-filter: blur(5px);
  z-index: 100; /* Make sure the backdrop is above other content */
}
@media (max-width:510px) {
  .img_main_div_blur{
    border-radius: 5px;
    width: 390px;
    height: 170px;
    overflow: hidden;
    margin: 0 auto;
  }
  .img_home{
    height: 200px;
    width: 450px;
  }
}
@media (max-width:400px){
  .img_main_div_blur{
    width: 290px;
    box-shadow: none;
  }
  .img_home{
    width: 280px;
    margin-left: -42px;

  }
}
  /*****************************************************************************/
  .typewriter-wrapper {
    display: inline-block;
    vertical-align: middle;
    white-space:normal;
    margin-top: -5rem;
  }
  .typewriter-cursor {
    animation: blink-caret 0.75s infinite;
  }
 
  
  @keyframes blink-caret {
    50% {
      opacity: 0;
    }
  }
  
  
  
  /*****************************************************************************/
  .h3_tagLine{
    transition: all 0.3s ease-in-out;
    color: rgb(37, 119, 173);
  }
  .h3_tagLine:hover{
    transform: scale(1.5);
  
  }
  .card_container_inner{
   /* position: relative; */

  }
  .card_container_inner> div:nth-child(1){
   
  } 
  .card_container_inner> div:nth-child(2){
  } 
  .card_container_inner> div:nth-child(3){
  } 
  .card_container_inner div {
    color: rgb(2, 5, 7);
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;
    margin-bottom: 1px;
    margin-top: 3rem;
  }
  .card_container_inner  h2{
    color: black;
   

  }
  .card_container_inner  h1{
    transform: scale(1);
    margin-bottom: 1rem;
    font-weight: 600;
    margin-top: 2rem;
   
  }
  .card_container_inner  h1 span{
    background: linear-gradient(90deg,
    hsl(206deg 78% 57%) 0%,
    hsl(212deg 65% 60%) 9%,
    hsl(217deg 52% 61%) 18%,
    hsl(224deg 40% 60%) 27%,
    hsl(224deg 37% 59%) 36%,
    hsl(211deg 48% 57%) 45%,
    hsl(202deg 55% 54%) 55%,
    hsl(195deg 61% 49%) 64%,
    hsl(195deg 56% 57%) 73%,
    hsl(198deg 52% 66%) 82%,
    hsl(199deg 48% 74%) 91%,
    hsl(201deg 42% 81%) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    
  }
 
  .card_container_inner_div select {
    width: 250px;
    height: 60px;
    border-radius: 5px;
    padding: 10px;
    border: none;
  }
  .sticky_btn {
    position: fixed;
    top: 30%;
    z-index: 9999;
    width: 80px;
    right: 0;
   
  }
  
  .sticky_btn ul {
    
    list-style: none;
    padding-left: 0;
  }
  
  .sticky_btn li {
    display: block;
    margin: 5px;
    background: rgba(0, 0, 0, 0.36);
    width: 210px;
    padding: 10px 1px;
    border-radius: 30px 0 0 30px;
    transition: all 1s;
    float: left;
    color: aliceblue;
   
  }
  .sticky_btn li span {
    margin-left: 10px;
  }
  .sticky_btn_l1:hover {
    transform: translateX(-140px);
    transition: all 0.3s ease-in-out;
  }
  
  .sticky_btn_l2:hover {
    transform: translateX(-140px);
    transition: all 0.3s ease-in-out;
  }
  .service_portion {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .service_no{
  }
  .services_container {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    
    min-height: 10vh;
    align-items: center;
    padding: 1px;
  }
  .services_container div {
    margin-right: 70px;
  }
  .services_container .service {
  }
  .services_container div h2,
  h3 {
    word-wrap: break-word;
    font-size:large;
    
  }
  @media (max-width: 400px) {
    .card_container_inner  h1
    {
        margin-bottom: 1rem;
        font-weight: 600;
        margin-top: 2rem;
      transform: scale(1);
       
       
     }
  }

  @media (max-width: 991px) {
    .card_container {
      background-size: contain;
    }
    .card_container_inner_div select {
      width: 150px;
    }
    .card_container_inner div {
      padding: 10px 0;
    }
    .service_portion {
      display: flex;
    }
    
    .services_container {
      display: flex;
      flex-direction: column;
      width:100vw;
      padding: 3rem 0 3rem 0;
      
    }
    .services_container .service{
      border: none;
    }
    .service {
      margin-bottom: 3rem;
    }
    .service_no {
      margin-bottom: 5rem;
      border-bottom: 2px solid black;
    }
    .services_container div h2,
  h3 {
   font-size:large;
  }
  }
/*****************************************Here is second part of Home page******************************************************/
  
/* .see_all_button{
  background-color: rgb(16, 228, 16);
  padding: 1rem;
  border-radius: 1rem;
  
} */
.see_all_button {
  position: relative;
  padding: 1rem;
  border-radius: 1rem;
  background: linear-gradient(90deg, rgba(105, 136, 223, 0.5), rgb(56, 192, 216), rgba(93, 128, 202, 0.5));
  overflow: hidden;
  transition: transform 0.7s ease-in-out;

}

.see_all_button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(40, 180, 99, 0.3), rgba(40, 180, 99, 0.7), rgba(40, 180, 99, 0.3));
  animation: shining-animation 2s linear infinite;
}
.see_all_button:hover{
  background-color: #46C2CB;
}
/* .see_all_button:hover::before {
  transform: rotateY(-180deg);
} */

@keyframes see_all_button{
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* .see_all_button {
  position: relative;
  background-color: #2ecc71; 
  overflow: hidden;
  padding: 1rem;
  border-radius: 1rem;
}

.see_all_button::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
  animation: shining-animation 2s linear infinite;
}

@keyframes see_all_button {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
} */

.see_all_button a{
  color: white;
}
.tag_line{
    text-align: center;
    margin-top: 5px;
    margin-bottom: 3rem;
}
.expert_card{

    min-height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
}
@media (max-width:374px) {
  .expert_card{
    padding-bottom: 8rem;
  }
  
}
a{
 color:black;
}
a:hover{
  color:white;
 }

.row{
  display: flex;
  justify-content: center;

}

.card_div {
  display: flex;
  justify-content: center;
  text-align: center;
    margin-bottom: 15px;
   margin-right: 15px;
    background-color: white;
    box-shadow: rgba(78, 78, 156, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    height: 150px;
    width: 250px;
    border-radius: 1rem;
    transition: all 0.5s ease-in-out;

    animation: floatingAnimation 4s ease-in-out 5, slideIn 5s ease-in-out;

    /* animation-name: floatingAnimation;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 3; */

    /* animation: slideIn 10s forwards; */
 
  }
  @keyframes floatingAnimation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .card {
   
  }
  
  .card_div:hover {
    color: white; 
    background-color: #46C2CB; 
    /* background-color: rgb(199, 248, 203); */
  }
  .expert_card_wrapper {
    width: 100%;
    background-color:rgb(242, 249, 245);

    
    margin: 0 auto; 
  }
 
.inner_service_tile{
    background-color: aqua;
    margin: 10px;
}

  
  /******************************************* Here is the third Part of Home page***********************************************************/
  .why_us{
      min-height: 60vh;
      background-color: rgb(242,249,245);
     
  }
  .row_here{
      display: flex;
      align-items: center;
      justify-content: space-between;
      
  }
  /* .third_page_div{
    padding: 20px;
  } */
  .why_row{
    /* margin-bottom: 20px; */
    /* padding-bottom: 1rem; */
  }
  .col_left{
    margin-right: 30rem;
   
  }
  .col_right{
      display: flex;
      justify-content: center;
      margin-left: -27rem;
  }
  .card_point{
      display: flex;
      justify-content: center;
      text-align: center;
        margin-bottom: 15px;
       margin-right: 15px;
        background-color: white;
        box-shadow: rgba(78, 78, 156, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        height: 150px;
        width: 250px;
        border-radius: 1rem;
        transition: all 0.5s ease-in-out;

    animation: floatingAnimation 4s ease-in-out 5;


        /* animation-name: floatingAnimation;
        animation-duration: 5s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 3; */

        /* animation: slideInRight 10s forwards; */
  }
  /* @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  } */
  .card_point:hover{
    color: white;  
    /* background-color: rgb(199, 248, 203); */
    background-color: #46C2CB; 

  }
  .read{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:70vh;
  }
  .dee{
    color: red;
  }
  .em{
    color: orange;
  }
  .tee{
    color: green;
  }
  .read h1 span{
    font-weight: 600;
  }
  @media (max-width:1084px) {
   .why_us{
   padding-top: 4rem;
  
   }
    
  }
  @media (max-width:768px){
    .why_row{
      margin-bottom: 1rem;
    }
    .why_us{
      padding-top: 1rem;
      
      }
  }
  @media (max-width:510px){

   .why_text{
    font-size: smaller;
   }

  }
  @media (max-width:360px){

    .why_text{
     font-size:x-small;
    }
 
   }
 