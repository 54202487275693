.contact-us-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
 

}

.contact-us-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px; */

}
.contact_box{
  position: relative;
  min-width: 100vw;
  min-height: 40vh;
  background-color: rgb(242, 249, 245);
}
.contact_img{
  margin-right: 0;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: transparent; */
  background-image: linear-gradient(
      to right,
      white 24%,
      rgba(255, 255, 255, 0) 100%
    ),
    url("https://images.pexels.com/photos/1416530/pexels-photo-1416530.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: fadeIn 2s ease-in-out forwards;

}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.left_content_contact_inner{
  justify-content: flex-start;
  margin-left: 1rem;
  animation-name: floatingAnimation;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 3;
}
.top_section{
  background-color: white;
  min-width: 80vw;
  height: 40vh;
}
.left-section {
  flex: 1;
  /* padding-right: 60px; */
}
.right_img{
  /* position: absolute;
  right: 0; */
  width: 30rem;
  /* object-fit: contain;
  top: 6rem;
  height: 17rem; */
  
}
.right-section {
 
  flex: 1;
}
.mid_contact_section{
  padding: 4rem;
}

.icon,
.text {
  flex: 1;
  padding: 20px;
}

.text h3 {
  margin-bottom: 10px;
}
.contact_border_left{
  border-left: 1px solid black;
}
.contact_margin{
  padding-left: 1rem;

}
.map-container {
  width: 100%;
  height: 100vh;
}
.map-container iframe{
  width: 200%;
}
.mid_part_contact{
  padding: 5rem;
}

/* Responsive Styles */
@media (max-width:991px){
  .contact_border_left{
    border-left: none;
  }
}
@media (max-width: 768px) {
  .contact-us-section {
    flex-direction: column;
  }

  .left-section,
  .right-section,
  .icon,
  .text {
    padding: 10px;
  }
  .map-container{
    width: 80%;
  }
  .map-container iframe {
 
  width: 100%;


  }
  .contact_img{
    display: none;
  }
  .contact_box{
     min-height: 20vh;
  }
  .top_section{
    height: 12vh;
  }
}


/* Additional Styles */

/* Add your own custom styles here */
